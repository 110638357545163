import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import avainapteekitLogo from '../../images/partner/avainapteekit.svg';
import duodecimLogo from '../../images/partner/duodecim.svg';
import orionLogo from '../../images/partner/orion.svg';
import siloAILogo from '../../images/partner/silo-ai.svg';
import receptumLogo from '../../images/partner/receptum.svg';
import pharmadataLogo from '../../images/partner/pharmadata.svg';
import eezeryLogo from '../../images/partner/eezery.svg';
import checkoutLogo from '../../images/partner/checkout.svg';
import andersLogo from '../../images/partner/anders.svg';
import apteekkariLiittoLogo from '../../images/partner/apteekkariliitto.svg';
import terveystaloLogo from '../../images/partner/terveystalo.png';
import tevaLogo from '../../images/partner/teva.png';
import vermanLogo from '../../images/partner/verman.png';
import nostettaLogo from '../../images/partner/nostetta.png';
import alkLogo from '../../images/partner/alk-logo.svg';
import woltLogo from '../../images/partner/wolt.png';
import { Header } from '../elements';

const usePartnerStyles = makeStyles(theme => ({
  paper: {
    boxShadow: 'none',
    minHeight: 135,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  imageContainer: {
    lineHeight: '115px',
  },
  image: {
    height: 'auto',
    marginBottom: theme.spacing(2),
    maxHeight: 120,
    maxWidth: 180,
    width: '100%',
    verticalAlign: 'middle',
  },
  smallerImage: {
    height: 'auto',
    marginBottom: theme.spacing(2),
    maxHeight: 75,
    maxWidth: 200,
    width: '100%',
    verticalAlign: 'middle',
  },
}));

const Partner: React.FunctionComponent<{ logo: string; companyName: string; smallerLogo?: boolean }> = ({
  logo,
  companyName,
  smallerLogo,
}) => {
  const classes = usePartnerStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <Grid item className={classes.imageContainer}>
          <img src={logo} alt={companyName} className={smallerLogo ? classes.smallerImage : classes.image} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const useSectionStyles = makeStyles(theme => ({
  container: {
    fontWeight: 900,
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
}));

const PartnerSection: React.FunctionComponent = () => {
  const classes = useSectionStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={2} justify="center">
        <Grid item lg={12} xs={12}>
          <Header styling="dark" variant="h3" center={true}>
            Yhteistyössä
          </Header>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Avainapteekit" logo={avainapteekitLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Apteekkariliitto" logo={apteekkariLiittoLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Receptum" logo={receptumLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Pharmadata" logo={pharmadataLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Wolt" logo={woltLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Terveystalo" logo={terveystaloLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Duodecim" logo={duodecimLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Orion" logo={orionLogo} smallerLogo />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Teva" logo={tevaLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Verman" logo={vermanLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Anders" logo={andersLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Eezery" logo={eezeryLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Checkout" logo={checkoutLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Nostetta ventures" logo={nostettaLogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="Silo.AI" logo={siloAILogo} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Partner companyName="ALK" logo={alkLogo} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnerSection;
