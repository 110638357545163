import { useEffect, useState } from 'react';

type FetchStatus = undefined | 'fetching' | 'done' | 'error';

type Statistics = {
  pharmacyCount: number;
  pharmacistCount: number;
  cities: string[];
};

type Pharmacy = {
  city: string;
};

export const useStatistics = () => {
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>();
  const [statistics, setStatistics] = useState<Statistics>({
    pharmacyCount: 0,
    pharmacistCount: 0,
    cities: [],
  });

  useEffect(() => {
    setFetchStatus('fetching');
    fetch('https://api.treet.fi/media/statistics.json')
      .then(response => response.json())
      .then(data => {
        setStatistics({
          pharmacyCount: data.pharmacies.length,
          pharmacistCount: data.pharmacists,
          cities: Array.from(
            new Set<string>(data.pharmacies.map((pharmacy: Pharmacy) => pharmacy.city)).values(),
          ).sort(),
        });
        setFetchStatus('done');
      })
      .catch(() => {
        setFetchStatus('error');
      });
  }, []);

  return { fetchStatus, statistics };
};
