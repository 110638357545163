import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Carousel from 'react-elastic-carousel';
import { StyledLink } from '../elements';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ImageSource, MarkdownRemark } from '../../types/graphql_types';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F8F9FA',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  image: {
    height: '220px',
    width: '100%',
    backgroundColor: '#f3f5f7',
    borderRadius: '9px 9px 0 0',
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(0, 4),
  },
  carouselDecoration: {
    '& .rec.rec-arrow-left': {
      backgroundColor: '#fff',
    },
    '& .rec.rec-arrow-right': {
      backgroundColor: '#fff',
    },
    '& .rec.rec-arrow:hover:enabled': {
      backgroundColor: theme.palette.treetDarkGreen.main,
    },
    '& .rec.rec-arrow:focus:enabled': {
      backgroundColor: theme.palette.treetDarkGreen.main,
    },
    '& .rec.rec-pagination': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      '& .rec.rec-arrow': {
        width: '35px',
        height: '35px',
        minWidth: '35px',
        lineHeight: '35px',
        fontSize: '1em',
        alignSelf: 'center',
      },
    },
  },
  boxContainer: {
    height: '426px',
    width: '100%',
    borderRadius: '9px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 15%)',
    '&:hover': {
      color: '#26609A',
      boxShadow: '0px 8px 10px rgb(0 0 0 / 45%)',
    },
  },
}));

const BlogPostSection: FunctionComponent = () => {
  const classes = useStyles();

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 960, itemsToShow: 3 },
  ];

  const markdownQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
        edges {
          node {
            html
            frontmatter {
              url
              title
              image
            }
          }
        }
      }
      allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sourceInstanceName: { eq: "markdown-news-images" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  const markdowns: [MarkdownRemark] = markdownQuery.allMarkdownRemark.edges;
  const markdown_preview_images: [ImageSource] = markdownQuery.allFile.edges;

  return (
    <Container className={classes.container}>
      <Typography className={classes.header} variant="h2">
        Uutta ja ajankohtaista
      </Typography>
      <Carousel className={classes.carouselDecoration} breakPoints={breakPoints} isRTL={false}>
        {markdowns.map((markdown: MarkdownRemark) => {
          const {
            node: {
              frontmatter: { url, title, image },
            },
          } = markdown;
          const previewImage: ImageSource | undefined = markdown_preview_images.find(
            (markdown_preview_image: ImageSource) => {
              return markdown_preview_image.node.name === image?.split('.png')[0];
            },
          );
          return (
            <StyledLink key={title} href={url} style={{ textDecoration: 'none' }}>
              <Box className={classes.boxContainer} display="flex" flexDirection="column" justifyContent="flex-start">
                {previewImage ? (
                  <Img className={classes.image} fluid={previewImage?.node.childImageSharp.fluid} />
                ) : (
                  <div className={classes.image} />
                )}
                <Typography className={classes.title} variant="h4">
                  {title}
                </Typography>
              </Box>
            </StyledLink>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default BlogPostSection;
