import Hidden from '@material-ui/core/Hidden';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import { Layout } from '../components';
import {
  BlogPostSection,
  KeyFeaturesSection,
  LandingSection,
  OperatingCitiesSection,
  PartnerSection,
  SecondHypeSection,
  StatisticsSection,
  TestimonialSection,
} from '../components/home';
import { useStatistics } from '../hooks';
import favicon from '../images/treet-favicon.png';

const IndexPage: FunctionComponent = () => {
  const { fetchStatus, statistics } = useStatistics();

  return (
    <>
      <Helmet>
        <title>Treet-verkkoapteekki</title>
        <meta
          name="description"
          content="Lähin apteekkisi on netissä. ✓ Tilaa 24/7 ✓ Nopein toimitus ✓ Reseptilääkkeet, apteekkituotteet, kosmetiikka ✓ Treet.fi + Treet-sovellus"
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout>
        <LandingSection />
        <KeyFeaturesSection />
        {fetchStatus === 'done' && (
          <>
            <StatisticsSection pharmacyCount={statistics.pharmacyCount} pharmacistCount={statistics.pharmacistCount} />
            <TestimonialSection />
            <Hidden xsUp>
              <OperatingCitiesSection cities={statistics.cities} />
            </Hidden>
          </>
        )}
        <Hidden xsUp>
          <SecondHypeSection />
        </Hidden>
        <BlogPostSection />
        <Hidden xsDown>
          <PartnerSection />
        </Hidden>
      </Layout>
    </>
  );
};

IndexPage.displayName = 'IndexPage';

export default IndexPage;
