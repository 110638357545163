import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Header } from '../elements';
import basketIcon from '../../images/features/basket_icon.svg';
import chatIcon from '../../images/features/chat_icon.svg';
import deliveryIcon from '../../images/features/delivery_icon.svg';
import rightArrowIcon from '../../images/features/arrow_right.svg';
import theme from '../../theme';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  image: {
    height: 136,
    width: 208,
  },
  featureTitle: {
    marginTop: '0.1em',
    marginBottom: '0.7em',
  },
  featureText: {
    textAlign: 'center',
    fontSize: theme.spacing(2),
  },
  featureColumn: {
    paddingTop: '2em',
  },
  loginLink: {
    fontWeight: 'bold',
    color: theme.palette.treetDarkGreen.main,
  },
}));

const KeyFeaturesSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });
  return (
    <Container maxWidth="lg">
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item xs={12}>
          <Header styling="dark" variant="h2" center>
            Helpointa apteekkiasiointia
          </Header>
        </Grid>
        <Grid container alignItems="flex-start" alignContent="center" justify="center" spacing={isMobile ? 0 : 2}>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            xs={8}
            sm={4}
            md={3}
            className={classes.featureColumn}
          >
            <Grid item justify="center">
              <img src={basketIcon} alt="" className={classes.image} />
            </Grid>
            <Grid item justify="center">
              <Typography variant="h4" className={classes.featureTitle}>
                Verkkoapteekki
              </Typography>
            </Grid>
            <Grid item justify="center">
              <Typography variant="body1" className={classes.featureText}>
                <a className={classes.loginLink} href="https://api.treet.fi/suomi-fi-authenticate/">
                  Kirjaudu Treet-palveluun
                </a>{' '}
                netissä tai avaa Treet-mobiilisovellus.
              </Typography>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              alignContent="center"
              xs={1}
              sm={1}
              md={1}
              className={classes.featureColumn}
            >
              <img src={rightArrowIcon} alt="" className={classes.image} />
            </Grid>
          )}
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            xs={8}
            sm={4}
            md={3}
            className={classes.featureColumn}
          >
            <Grid item justify="center">
              <img src={chatIcon} alt="" className={classes.image} />
            </Grid>
            <Grid item justify="center">
              <Typography variant="h4" className={classes.featureTitle}>
                Lääkeneuvonta
              </Typography>
            </Grid>
            <Grid item justify="center">
              <Typography variant="body1" className={classes.featureText}>
                Kerro farmaseutille, mitä tarvitset, tai lisää tuotteet oskoskoriisi itse.
              </Typography>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              alignContent="center"
              xs={1}
              sm={1}
              md={1}
              className={classes.featureColumn}
            >
              <img src={rightArrowIcon} alt="" className={classes.image} />
            </Grid>
          )}
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            xs={8}
            sm={4}
            md={3}
            className={classes.featureColumn}
          >
            <Grid item justify="center">
              <img src={deliveryIcon} alt="" className={classes.image} />
            </Grid>
            <Grid item justify="center">
              <Typography variant="h4" className={classes.featureTitle}>
                Nopein toimitus
              </Typography>
            </Grid>
            <Grid item justify="center">
              <Typography variant="body1" className={classes.featureText}>
                Toimitamme tilauksesi sinulle muutamassa tunnissa.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KeyFeaturesSection;
