import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { Header, TestimonialItem } from '../elements';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(10),
    textAlign: 'center',
    marginBottom: theme.spacing(11.25),
  },
  toprow: {
    marginBottom: '2em',
  },
  testimonialItem: {
    marginBottom: theme.spacing(2.5),
  },
}));

const TestimonialSection: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={2} justify="center">
        <Grid item lg={12} xs={12} className={classes.toprow}>
          <Header styling="dark" variant="h2" center={true}>
            Asiakkaidemme kokemuksia
          </Header>
        </Grid>

        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Nopea, ystävällinen ja kaikin tavoin erinomainen." />
        </Grid>
        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Tosi hyvin sujui kaikki." />
        </Grid>
        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Selkeä ja avulias palvelu." />
        </Grid>
        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Tilaaminen on helppoa ja sovellus on helppo käyttää. Huippua palvelua!" />
        </Grid>
        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Tosi hyvin sujui, kuten aina, ihana palvelu, kiitos siitä." />
        </Grid>
        <Grid className={classes.testimonialItem} item md={4} sm={12} xs={12}>
          <TestimonialItem text="Allt fungerade utmärkt. Varorna levererades snabbt. TACK! :-)" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TestimonialSection;
