import { Box, Container, Grid, Hidden, makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { AppDownloadLinks } from '../icon';
import { Header, BodyText, PharmacySearchButton } from '../elements';

const useStyles = makeStyles(theme => ({
  background: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#F8F9FA',
    },
  },
  backgroundImage: {
    position: 'absolute',
    height: '650px',
  },
  buttonDecoration: {
    fontSize: '20px',
    fontWeight: 700,
    color: 'white',
    height: '56px',
    width: '90%',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#4FAD4F',
    },
    borderRadius: '32px',
    textTransform: 'none',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.treetDarkGreen.main,
  },
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      overflow: 'hidden',
    },
  },
  text: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      padding: theme.spacing(4),
    },
  },
  appDownloadContainer: {
    marginTop: theme.spacing(3),
  },
}));

const LandingSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const imageQuery = useStaticQuery(graphql`
    query {
      landing_desktop: file(relativePath: { eq: "landing_desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landing_mobile: file(relativePath: { eq: "landing_mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box className={classes.background}>
      <Container className={classes.container}>
        <BackgroundImage
          fluid={isLargeScreen ? imageQuery.landing_desktop.childImageSharp.fluid : null}
          className={classes.backgroundImage}
        />
        <Grid container alignItems="center" spacing={4} className={classes.text}>
          <Grid item md={5} sm={12}>
            <Header styling="dark" variant="h1" center={false}>
              Apteekkisi – siellä missä sinäkin
            </Header>
            <BodyText styling="dark" size="normal">
              Etäasioi apteekeissa helposti ja turvallisesti joko internet-selaimen kautta tai mobiilisovelluksen
              avulla. Aloita asiointi valitsemalla haluamasi apteekki.
            </BodyText>
            <Box mt={2} mb={2}>
              <PharmacySearchButton isCapitalizedTitle={false} startIcon={<ArrowForwardOutlinedIcon />} />
            </Box>
            <BodyText styling="dark" size="normal">
              Treet-mobiilisovelluksella apteekkien palvelut ovat aina kätesi ulottuvilla.
            </BodyText>
            <Grid className={classes.appDownloadContainer} item xs={12}>
              <AppDownloadLinks isMainImageArea />
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            <Img fluid={imageQuery.landing_mobile.childImageSharp.fluid} />
          </Grid>
        </Hidden>
      </Container>
    </Box>
  );
};

export default LandingSection;
