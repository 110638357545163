/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { BigNumber } from '../elements';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    fontSize: 40,
    lineHeight: 56,
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(12),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxHeight: '232px',
      height: '100%',
      paddingTop: theme.spacing(7.5),
    },
  },
  topContainer: {
    backgroundColor: theme.palette.treetDarkGreen.main,
    [theme.breakpoints.up('md')]: {
      maxHeight: '232px',
      height: '100%',
    },
  },
  statText: {
    color: theme.palette.common.white,
    textAlign: 'center',
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(0),
  },
  statisticsContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

export type StatisticsProps = {
  pharmacyCount: number;
  pharmacistCount: number;
};

const StatisticsSection: React.FunctionComponent<StatisticsProps> = props => {
  const classes = useStyles();
  const gainedAverageRating = '8,9';

  return (
    <Grid container className={classes.topContainer}>
      <Container maxWidth="lg">
        <Grid container className={classes.container} justify="center">
          <Grid className={classes.statisticsContainer} item lg={4} md={4} xs={12}>
            <BigNumber>{props.pharmacyCount}</BigNumber>
            <Typography className={classes.statText} variant="h4">
              Apteekkia
            </Typography>
          </Grid>
          <Grid className={classes.statisticsContainer} item lg={4} md={4} xs={12}>
            <BigNumber>{props.pharmacistCount}</BigNumber>
            <Typography className={classes.statText} variant="h4">
              Farmaseuttia
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <BigNumber>{gainedAverageRating}</BigNumber>
            <Typography className={classes.statText} variant="h4">
              Asiakastyytyväisyys
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default StatisticsSection;
