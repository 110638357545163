import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Header } from '../elements';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    textAlign: 'center',
  },
  cityName: {
    fontFamily: 'Cera Round Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '150%',
    letterSpacing: '0.1px',
    color: theme.palette.treetDarkGray.main,
    marginTop: '0.8em',
    marginBottom: '0.8em',
  },
  toprow: {
    marginBottom: '2em',
  },
}));

export type OperatingCitiesProps = {
  cities: string[];
};

const OperatingCitiesSection: React.FunctionComponent<OperatingCitiesProps> = props => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={0} justify={props.cities.length > 0 ? 'flex-start' : 'center'}>
        <Grid item lg={12} xs={12} className={classes.toprow}>
          <Header styling="dark" variant="h2" center={true}>
            {props.cities.length > 0
              ? 'Treet on käytössä seuraavilla paikkakunnilla'
              : 'Treet toimii yhä useammalla paikkakunnalla'}
          </Header>
        </Grid>
        {props.cities.length > 0 ? (
          props.cities.map(city => (
            <Grid item md={3} sm={6} xs={12} key={city}>
              <Typography className={classes.cityName}>{city}</Typography>
            </Grid>
          ))
        ) : (
          <Grid item md={3} sm={6} xs={12}>
            <Header variant="h5" styling="dark" center={true}>
              Lataa sovellus ja tarkista lähin Treet-apteekkisi
            </Header>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OperatingCitiesSection;
