import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 450,
    height: 450,
    width: '100%',
    backgroundColor: '#bebed7',
    backgroundBlendMode: 'multiply',
  },
  grid: {
    height: '100%',
    padding: theme.spacing(0, 2),
  },
  heading: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: '34px',
    },
    textAlign: 'center',
    maxWidth: 1016,
    margin: 'auto',
  },
}));

const SecondHypeSection: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "hype-background-2-2.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1440, maxHeight: 375, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid;

        return (
          <BackgroundImage Tag="section" fluid={imageData} className={classes.container}>
            <Grid container alignContent="center" alignItems="center" className={classes.grid}>
              <Grid item sm={12} md={12}>
                <Typography component="h2" variant="h2" className={classes.heading}>
                  Sinun ei tarvitse olla yksin lääkehoitosi kanssa. TREET:in avulla sadat terveydenhuollon ammattilaiset
                  ovat apunasi.
                </Typography>
              </Grid>
            </Grid>
          </BackgroundImage>
        );
      }}
    />
  );
};

export default SecondHypeSection;
